import React, { useState } from 'react';
import { Modal, Input, Form } from 'antd';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

export default function NoteModal({ record, index, noteText, visible, onChangeNote }) {
  const { t } = useTranslation();

  const [noteValue, setnoteValue] = useState(noteText);
  const addNotes = ({ target: { value } }) => {
    setnoteValue(value);
  };
  return (
    <Modal
      visible={visible}
      onOk={() => onChangeNote(noteValue, record, index, 'notes')}
      onCancel={() => {
        onChangeNote('', record, index, 'notes');
      }}
      title={t('claims.note.title')}
    >
      <Form.Item>
        <p>{t('claims.note.content')}</p>
        <TextArea rows={4} onChange={addNotes} value={noteValue} />
      </Form.Item>
    </Modal>
  );
}
