import React, { useState, useEffect, useCallback } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import OptionSelection from 'components/OptionSelection';
import { statusDropdown } from 'model';
import * as JsSearch from 'js-search';
import { Table, DatePicker } from 'antd';
import { withRouter } from 'react-router-dom';
// import {UserRole} from 'enums';
import { CancelAndExitAndCreateModal } from 'components/CancelAndExitAndCreateModal';
import GetClaimColums from 'utils/getClaimColums';
import AppLoader from 'components/AppLoader';
import PropTypes from 'prop-types';
import Compare from 'utils/compare';

export function ClaimGeneral({
  t,
  actions,
  claimType,
  vendorList,
  userRole,
  userData,
  vendorId,
  claimDashboard,
  history,
  vendorListFetching,
  claimTypeFetching,
  userFetching,
  claimDashboardFetching
}) {
  const [gridScrollHeight, setGridScrollHeight] = useState('70vh');
  const [searchByClaimId, setSearchByClaimId] = useState(null);
  const [searchByVendor, setSearchByVendor] = useState(null);
  const [opcos, setOpcos] = useState([]);
  const [filterClaimDetails, setFilterClaimDetails] = useState({
    claimType: '',
    endDate: '',
    id: 0,
    opcoId: '',
    po: 0,
    startDate: '',
    status: ''
  });

  useEffect(() => {
    if (userRole === 'vendor') {
      vendorList.forEach(vendor => {
        if (vendor.vendorId === vendorId) {
          setOpcos(vendor.opcos);
        }
      });
    } else {
      setOpcos(vendorList[0].opcos);
    }
  }, [userRole, opcos, vendorList, vendorId]);

  const loadFiltered = useCallback(
    filterDetails => {
      // if (userRole === UserRole.VENDOR) {
      actions.loadClaimDashboard({ ...filterDetails, vendorNo: vendorId, userId: userData.userId });
      // }
    },
    [actions, vendorId, userData.userId]
  );

  useEffect(() => {
    actions.loadClaimDashboard({ ...filterClaimDetails, vendorNo: vendorId, userId: userData.userId });
  }, [actions, filterClaimDetails, vendorId, userData.userId]);

  const onResize = (width, height) => {
    setGridScrollHeight(height - 230);
  };

  const onChangeClaimId = value => {
    value.persist();
    setSearchByClaimId(value.target.value);
  };
  const onChangeVendor = value => {
    value.persist();
    setSearchByVendor(value.target.value);
  };
  const onChangeType = (value, item) => {
    let data =
      value === t('claims.all')
        ? { ...filterClaimDetails, claimType: '' }
        : { ...filterClaimDetails, claimType: value };
    setFilterClaimDetails(data);
    loadFiltered(data);
  };
  const onChangeStatus = value => {
    let data =
      value === t('claims.all') ? { ...filterClaimDetails, status: '' } : { ...filterClaimDetails, status: value };
    setFilterClaimDetails(data);
    loadFiltered(data);
  };
  const onChangeDate = (date, dateString) => {
    let data = { ...filterClaimDetails, startDate: dateString[0], endDate: dateString[1] };
    setFilterClaimDetails(data);
    loadFiltered(data);
  };
  const onChangeOpco = value => {
    let data =
      value === t('claims.all') ? { ...filterClaimDetails, opcoId: '' } : { ...filterClaimDetails, opcoId: value };
    setFilterClaimDetails(data);
    loadFiltered(data);
  };
  const filterUserSearch = (data, searchByClaimId, searchByVendor) => {
    const searchClaimId = new JsSearch.Search('id');
    const searchPo = new JsSearch.Search('id');
    const searchVendor = new JsSearch.Search('id');
    searchClaimId.addIndex('id');
    searchPo.addIndex('po');
    searchPo.addIndex('cbSMSPO');
    searchVendor.addIndex('vendorNo');
    if (data) {
      searchClaimId.addDocuments(data);
      searchPo.addDocuments(data);
      if (searchByClaimId) {
        const list1 = searchClaimId.search(searchByClaimId);
        const list2 = searchPo.search(searchByClaimId);
        list1.forEach(ele => {
          let tId = ele.id;
          let isExist = false;
          list2.forEach(rEle => {
            let rId = rEle.id;
            if (rId === tId) {
              isExist = true;
            }
          });
          if (isExist === false) {
            list2.push(ele);
          }
        });
        return list2;
      } else if (searchByVendor) {
        searchVendor.addDocuments(data);
        return searchVendor.search(searchByVendor).sort((a, b) => Compare(a.id, b.id));
      } else {
        return data;
      }
    }
    return data;
  };
  const { RangePicker } = DatePicker;
  let claimTable = claimDashboard;
  claimTable = filterUserSearch(claimDashboard, searchByClaimId, searchByVendor);
  const columns = GetClaimColums(t, vendorId);
  let list = [];
  columns.forEach(column => {
    if (userRole !== 'vendor' || (column.key !== 'vendorNo' && column.key !== 'vendorName')) {
      list.push(column);
    }
  });
  const fetching = vendorListFetching || claimTypeFetching || claimDashboardFetching || userFetching;
  return (
    <React.Fragment>
      {fetching && <AppLoader show />}
      <div className="grid-filter-panel">
        <div className="title">{t('claims.supplier')}</div>
        <input type="search" className="search" placeholder={t('claims.searchByClaimId')} onChange={onChangeClaimId} />
        <CancelAndExitAndCreateModal
          isClaimPage={true}
          vendorId={vendorId}
          history={history}
          actions={actions}
          userRole={userRole}
        />
      </div>
      <div className="grid-filter-panel">
        <div className="spacer">
          {userRole !== 'vendor' ? (
            <div className="vendorSearch">
              <label htmlFor="VendorFilter">{t('claims.vendor#')}</label>
              <input
                type="search"
                className="search"
                placeholder={t('claims.searchByVendor')}
                onChange={onChangeVendor}
              />
            </div>
          ) : (
            <div className="spacer"></div>
          )}
        </div>
        <div className="filters">
          {userRole === 'vendor' ? (
            <div className="filter">
              <label htmlFor="statusFilter">{t('claims.type')}</label>
              <OptionSelection
                id="typeFilter"
                className="filter-select"
                placeholder="Please select"
                dropdownMatchSelectWidth={false}
                onChange={onChangeType}
                defaultValue={t('claims.all')}
                valueOptions={claimType.claimTypes.map(type => ({ id: type.name, name: type.name }))}
              />{' '}
            </div>
          ) : null}
          <div className="filter">
            <label htmlFor="statusFilter">{t('claims.status')}</label>
            <OptionSelection
              id="statusFilter"
              className="filter-select"
              placeholder="Please select"
              dropdownMatchSelectWidth={false}
              onChange={onChangeStatus}
              defaultValue={t('claims.all')}
              valueOptions={statusDropdown}
            />
          </div>
          <div className="filter">
            <label htmlFor="effectiveDatesFilter">{t('claims.claimDates')}</label>
            <RangePicker
              id="claimsDatesFilter"
              onChange={onChangeDate}
              className="filter-date-range"
              defaultValue={null}
            />
          </div>
          <div className="filter">
            <label htmlFor="opcoFilter">{t('claims.opco')}</label>
            <OptionSelection
              id="opcoFilter"
              className="filter-select"
              placeholder="Please select"
              dropdownMatchSelectWidth={false}
              onChange={onChangeOpco}
              defaultValue={t('claims.all')}
              valueOptions={opcos.map(opco => ({ id: opco.opcoId, name: opco.opcoId + '-' + opco.opcoName }))}
            />
          </div>
        </div>
      </div>

      <ReactResizeDetector handleHeight onResize={onResize} targetDomEl={this}>
        <div className="grid-panel with-scroller">
          <Table
            id="claimTable"
            pagination={{ showSizeChanger: true, pageSizeOptions: ['10', '25', '50', '100', '500'] }}
            columns={list}
            dataSource={claimTable}
            scroll={{ x: '100%', y: gridScrollHeight }}
            rowKey={(record, index) => index}
          />
        </div>
      </ReactResizeDetector>
    </React.Fragment>
  );
}

export default withRouter(ClaimGeneral);

ClaimGeneral.propTypes = {
  vendorId: PropTypes.string,
  vendorList: PropTypes.array,
  userRole: PropTypes.string,
  vendorListFetching: PropTypes.bool,
  claimDashboardFetching: PropTypes.bool,
  action: PropTypes.any,
  claimType: PropTypes.object,
  claimDashboard: PropTypes.array
};
