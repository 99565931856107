import { createRequestTypes } from 'reduxHelpers';

const USER = createRequestTypes('USER');
const CLAIMTYPE = createRequestTypes('CLAIMTYPE');
const CLAIMDASH = createRequestTypes('CLAIMDASH');
const PODETAIL = createRequestTypes('PODETAIL');
const CLAIMDETAILS = createRequestTypes('CLAIMDETAILS');
const CREATECLAIM = createRequestTypes('CREATECLAIM');
const SAVECLAIM = createRequestTypes('SAVECLAIM');
const UPDATECLAIMSTATUS = createRequestTypes('UPDATECLAIMSTATUS');

export { USER, CLAIMTYPE, CLAIMDASH, PODETAIL, CLAIMDETAILS ,CREATECLAIM ,SAVECLAIM ,UPDATECLAIMSTATUS};
