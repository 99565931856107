import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import * as claimsActions from 'actions';
import PropTypes from 'prop-types';
import NewClaimGeneral from 'components/NewClaimGeneral';
import { withRouter } from 'react-router-dom';

export function CreateClaimsView({
  actions,
  userId,
  email,
  claimType,
  poDetail,
  location,
  vendorList,
  history,
  claimTypeFetching,
  poDetailFetching,
  vendorListFetching
}) {
  const { vendorId } = location.state;

  useEffect(() => {
    if (claimType.length === 0) {
      actions.loadClaimType();
    }
  }, [actions, claimType]);

  return (
    <NewClaimGeneral
      actions={actions}
      vendorId={vendorId}
      userId={userId}
      email={email}
      history={history}
      vendorList={vendorList}
      claimType={claimType}
      poDetail={poDetail}
      vendorListFetching={vendorListFetching}
      claimTypeFetching={claimTypeFetching}
      poDetailFetching={poDetailFetching}
    />
  );
}

function mapStateToProps(state) {
  return {
    userId: state.user.data.userId,
    email: state.user.data.email,
    vendorList: state.user.data.vendorList,
    vendorListFetching: state.user.fetching,
    claimType: state.claimType.data.claimTypes,
    claimTypeFetching: state.claimType.fetching,
    poDetail: state.poDetail.data,
    poDetailFetching: state.poDetail.fetching
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(claimsActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(CreateClaimsView)));

CreateClaimsView.propTypes = {
  // userId: propTypes.string,
  // claimType: propTypes.array,
  // invoiceDetail: propTypes.array,
  claimTypefetching: PropTypes.bool,
  invoiceDetailfetching: PropTypes.bool
};
