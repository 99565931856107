import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { withTranslation } from 'react-i18next';

export function OptionSelect({ valueOptions, t, filterType, itemName, noAll, ...other }) {
  const { Option } = Select;
  return (
    <Select {...other} id={filterType}>
      {noAll ? null : (<Option value="" id="allOption">
        {t('claims.all')}
      </Option>)}
      {valueOptions.map((valueOption, index) => {
        return (
          <Option key={index} value={valueOption.id}>
            {valueOption.name}
          </Option>
        );
      })}
    </Select>
  );
}

export default withTranslation()(OptionSelect);

OptionSelect.propTypes = {
  valueOptions: PropTypes.array,
  t: PropTypes.func,
  other: PropTypes.any
};
