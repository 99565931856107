import React from 'react';
import { Provider } from 'react-redux';
import store from 'store';
import i18n from './_i18n';
import MainApp from './MainApp';

function App(props) {
  const {
    language,
    vendorId,
    Auth: {
      user: { username }
    }
  } = props;
  console.log('from landing',props);
  i18n.changeLanguage(language);
  return (
    <Provider store={store}>
      <MainApp vendorId={vendorId} userId={username} />
    </Provider>
  );
}

export default App;
