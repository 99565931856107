
import { Modal } from 'antd';

export default function ErrorMessageModal({
  errorTitle,
  errorContent
}) {

  Modal.error({
    title : errorTitle,
    content : errorContent,
  })
}
