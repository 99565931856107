import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import * as claimsActions from 'actions';
import PropTypes from 'prop-types';
import ClaimDetailsGeneral from 'components/ClaimDetailsGeneral';

export function ClaimDetails({
  actions,
  t,
  email,
  claimDetails,
  location,
  claimDetailsFetcing,
  userRole,
  userId,
  claimTypes
}) {
  const { vendorId, record } = location.state;
  useEffect(() => {
    let vendorNo = userRole === 'vendor' ? vendorId : record.vendorNo;
    actions.loadClaimDetails(vendorNo, userId, record);
  }, [actions, location.state, record, userId, userRole, vendorId]);

  useEffect(() => {
    if (claimTypes.length === 0) {
      actions.loadClaimType();
    }
  }, [actions, claimTypes]);

  return (
    <ClaimDetailsGeneral
      actions={actions}
      t={t}
      vendorId={vendorId}
      email={email}
      userRole={userRole}
      userId={userId}
      claimDetails={claimDetails}
      claimDetailsFetcing={claimDetailsFetcing}
      claimTypes={claimTypes}
    />
  );
}

function mapStateToProps(state) {
  return {
    userRole: state.user.data.userRole,
    userId: state.user.data.userId,
    email: state.user.data.email,
    claimDetails: state.claimDetails.data,
    claimDetailsFetcing: state.claimDetails.fetching,
    claimTypes: state.claimType.data.claimTypes
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(claimsActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClaimDetails));

ClaimDetails.propTypes = {
  actions: PropTypes.any,
  userRole: PropTypes.string,
  userId: PropTypes.string,
  claimDetails: PropTypes.object,
  claimDetailsFetcing: PropTypes.bool,
  location: PropTypes.object
};
