import { action } from 'reduxHelpers';
import { USER, CLAIMTYPE, CLAIMDASH, PODETAIL, CLAIMDETAILS ,CREATECLAIM ,SAVECLAIM ,UPDATECLAIMSTATUS} from './actionTypes';

const loadUserDetails = userId => action(USER.REQUEST, { userId });
const loadClaimType = () => action(CLAIMTYPE.REQUEST);
const loadClaimDashboard = data => action(CLAIMDASH.REQUEST, { data });
const loadpoDetails = ({ vendorId, opcoId, po }) => action(PODETAIL.REQUEST, { vendorId, opcoId, po });
const loadClaimDetails = (vendorId,userId, { opcoId, po, id ,status  }) => action(CLAIMDETAILS.REQUEST, { vendorId, userId ,opcoId, po, id ,status});
const createClaim = (data , history) => action(CREATECLAIM.REQUEST, {data ,history});
const saveClaim = (data , history) =>action(SAVECLAIM.REQUEST, {data ,history});
const emptyPoDetail = payload => action(PODETAIL.FAILURE, {payload});
const updateClaimStatus = data => action(UPDATECLAIMSTATUS.REQUEST , {data});

export { loadUserDetails, loadClaimType, loadClaimDashboard, loadpoDetails, 
    loadClaimDetails ,createClaim ,emptyPoDetail,saveClaim ,updateClaimStatus};
