import { takeLatest, put, all, takeEvery, call } from 'redux-saga/effects';
import { getRequest, postRequest } from '_http';
import {
  USER,
  CLAIMTYPE,
  CLAIMDASH,
  PODETAIL,
  CLAIMDETAILS,
  CREATECLAIM,
  SAVECLAIM,
  UPDATECLAIMSTATUS
} from 'actions/actionTypes';
import openNotification from 'components/Notification';
import { action } from 'reduxHelpers';
import { split } from 'lodash';
import i18n from '_i18n';
// import { getCurrentUserId } from 'Authenticator';

const adPrefix = process.env.REACT_APP_AD_PREFIX;

const getSyscoUserId = username => {
  return username.indexOf(adPrefix) >= 0 ? split(username, '@')[0].replace(adPrefix, '') : username;
};

function* loadUserAsync({ userId }) {
  try {
    const response = yield getRequest(`/user/${getSyscoUserId(userId)}`);
    yield put({ type: USER.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: USER.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'USER LOADING ERROR'
      })
    );
  }
}

function* loadClaimTypeAsync() {
  try {
    const response = yield getRequest(`/types`);
    yield put({ type: CLAIMTYPE.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: CLAIMTYPE.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'CLAIM TYPE ERROR'
      })
    );
  }
}

function* loadClaimDashBoradAsync({ data }) {
  try {
    const response = yield call(postRequest, `/dashboard`, data);
    yield put({ type: CLAIMDASH.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: CLAIMDASH.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        data: error.message,
        className: 'error',
        message: i18n.t('claim.notification.ClaimDashBoradFetchError')
      })
    );
  }
}

function* loadPOsearchAsync({ vendorId, opcoId, po }) {
  try {
    const response = yield getRequest(`/po/`, { vendorId, opcoId, po });
    yield put({ type: PODETAIL.SUCCESS, payload: response.data.poDetails });
  } catch (error) {
    yield put({ type: PODETAIL.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        data: error.message,
        className: 'error',
        message: i18n.t('claim.notification.POsearchFetchError')
      })
    );
  }
}

function* loadClaimDetailsAsync({ vendorId, userId, opcoId, po, id, status }) {
  try {
    const response = yield getRequest(`/view/`, { vendorId, userId, opcoId, po, id, status });
    yield put({ type: CLAIMDETAILS.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: CLAIMDETAILS.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        data: error.message,
        className: 'error',
        message: i18n.t('claim.notification.ClaimDetailsFetchError')
      })
    );
  }
}

// function* createClaimAsync ({data , history}){
//   yield createClaim({data});
//   history.replace(`/suite/claims`)
// }

function* createClaimAsync({ data, history }) {
  try {
    const response = yield call(postRequest, `/create`, data);
    yield put({ type: CREATECLAIM.SUCCESS, payload: response.data });
    yield put(
      action('SHOW_NOTIFICATION', {
        className: 'success',
        message: i18n.t('claim.notification.CreateClaim') + response.data['claimsId'].toString()
      })
    );
    history.replace(`/suite/claims`);
  } catch (error) {
    yield put({ type: CREATECLAIM.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        data: error.message,
        className: 'error',
        message: i18n.t('claim.notification.CreateClaimError')
      })
    );
  }
}

function* saveClaimAsync({ data, history }) {
  try {
    const response = yield call(postRequest, `/save`, data);
    yield put({ type: SAVECLAIM.SUCCESS, payload: response.data });
    yield put(
      action('SHOW_NOTIFICATION', {
        className: 'success',
        message: i18n.t('claim.notification.SaveClaim') + response.data['claimsId'].toString()
      })
    );
    if (history !== undefined) {
      history.replace(`/suite/claims`);
    }
  } catch (error) {
    yield put({ type: SAVECLAIM.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        data: error.message,
        className: 'error',
        message: i18n.t('claim.notification.SaveClaimError')
      })
    );
  }
}

function* updateClaimStatusAsync({ data: { reason, opcoId, po, id, status, history } }) {
  try {
    const response = yield getRequest(`/update`, { reason, opcoId, po, id, status });
    yield put({ type: UPDATECLAIMSTATUS.SUCCESS, payload: response.data });
    yield put(
      action('SHOW_NOTIFICATION', {
        className: 'success',
        message: i18n.t('claim.notification.updateClaimStatus')
      })
    );
    history.push('/suite/claims');
  } catch (error) {
    yield put({ type: UPDATECLAIMSTATUS.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        data: error.message,
        className: 'error',
        message: i18n.t('claim.notification.updateClaimStatusError')
      })
    );
  }
}

function* showNotificationAsync(notificationAction) {
  const { message, description, className } = notificationAction;
  yield openNotification({ message, description, className });
}

function* watchLoadUser() {
  yield takeLatest(USER.REQUEST, loadUserAsync);
}

function* watchShowNotification() {
  yield takeEvery('SHOW_NOTIFICATION', showNotificationAsync);
}

function* watchLoadClaimType() {
  yield takeLatest(CLAIMTYPE.REQUEST, loadClaimTypeAsync);
}

function* watchLoadClaimDashBoradAsync() {
  yield takeLatest(CLAIMDASH.REQUEST, loadClaimDashBoradAsync);
}

function* watchLoadPOsearch() {
  yield takeLatest(PODETAIL.REQUEST, loadPOsearchAsync);
}

function* watchLoadClaimDetails() {
  yield takeLatest(CLAIMDETAILS.REQUEST, loadClaimDetailsAsync);
}

function* watchCreateClaim() {
  yield takeLatest(CREATECLAIM.REQUEST, createClaimAsync);
}

function* watchSaveClaim() {
  yield takeLatest(SAVECLAIM.REQUEST, saveClaimAsync);
}

function* watchUpdateClaimStatus() {
  yield takeLatest(UPDATECLAIMSTATUS.REQUEST, updateClaimStatusAsync);
}

export default function* rootSaga() {
  yield all([
    watchLoadUser(),
    watchShowNotification(),
    watchLoadClaimType(),
    watchLoadClaimDashBoradAsync(),
    watchLoadPOsearch(),
    watchLoadClaimDetails(),
    watchCreateClaim(),
    watchSaveClaim(),
    watchUpdateClaimStatus()
  ]);
}
