import React, { useState } from 'react';
import { Modal, Button, Icon, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

export default function DangerModal({
  visible,
  onToggle,
  actions,
  userRole,
  claimDetails: { id, po, opcoId },
  history
}) {
  const { t } = useTranslation();
  console.log(userRole === 'corp merch');

  const [commentValue, setCommentValue] = useState('');

  const onCancel = () => {
    onToggle(false);
  };

  const onOk = () => {
    onToggle(false);
    const rejectStatus = userRole === 'corp' ? 'corp reject' : 'reject';
    const data = {
      reason: commentValue,
      opcoId: opcoId,
      po: po,
      id: id,
      status: rejectStatus,
      history: history
    };
    actions.updateClaimStatus(data);
  };

  const onCommentChange = e => {
    setCommentValue(e.target.value);
  };

  return (
    <Modal
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <div className="modal-btn-row" key="row-1">
          <Button id="danger-modal-no-btn" key="No" onClick={onCancel} className="btn">
            {t('claims.modal.no')}
          </Button>
          <Button
            id="danger-modal-yes-btn"
            key="Yes"
            onClick={onOk}
            className="btn red-action-btn ant-btn-primary"
            disabled={!(2 <= commentValue.length && commentValue.length <= 1000)}
          >
            {t('claims.modal.yes')}
          </Button>
        </div>
      ]}
    >
      <div className="modal-confirm-label">
        <Icon className="icon" type="question-circle" theme="filled" />
        <div className="sub">{t('claims.aysrClaim')}</div>
      </div>
      <Form.Item>
        <TextArea rows={4} onChange={onCommentChange} value={commentValue} />
      </Form.Item>
    </Modal>
  );
}
