import React from 'react';
import Compare from 'utils/compare';

function getClaimDetailsColums(claimStatus, t) {
  const colums = [
    {
      title: t('claims.description'),
      dataIndex: 'description',
      width: '12vw',
      key: 'description',
      sorter: (a, b) => Compare(a.description, b.description),
      render: text => (
        <div title={text} className="truncated-desc-td long">
          {text}
        </div>
      )
    },
    {
      title: t('claims.brand'),
      dataIndex: 'brand',
      width: '7vw',
      key: 'brand',
      sorter: (a, b) => Compare(a.brand, b.brand),
      render: text => (
        <div title={text} className="truncated-desc-td width6">
          {text}
        </div>
      )
    },
    {
      title: t('claims.poCost'),
      dataIndex: 'vendorPOCost',
      width: 90,
      key: 'vendorPOCost',
      sorter: (a, b) => Compare(a.poCost, b.poCost),
      render: text => (
        <div title={text} className="truncated-desc-td width6">
          {text.toFixed(2)}
        </div>
      )
    },
    {
      title: t('claims.pack'),
      dataIndex: 'pack',
      width: '5vw',
      key: 'pack',
      sorter: (a, b) => Compare(a.pack, b.pack),
      render: text => (
        <div title={text} className="truncated-desc-td width4">
          {text}
        </div>
      )
    },
    {
      title: t('claims.itemSize'),
      dataIndex: 'itemSize',
      width: '7vw',
      key: 'itemSize',
      sorter: (a, b) => Compare(a.itemsize, b.itemsize),
      render: text => (
        <div title={text} className="truncated-desc-td width5">
          {text}
        </div>
      )
    },
    {
      title: t('claims.supc'),
      dataIndex: 'supc',
      width: '5vw',
      key: 'supc',
      sorter: (a, b) => Compare(a.supc, b.supc),
      render: text => (
        <div title={text} className="truncated-desc-td width4">
          {text}
        </div>
      )
    },
    {
      title: t('claims.mfg'),
      dataIndex: 'mfgProduct',
      width: '9vw',
      key: 'mfgProduct',
      sorter: (a, b) => Compare(a.mfgprod, b.mfgprod),
      render: text => (
        <div title={text} className="truncated-desc-td Width8">
          {text}
        </div>
      )
    },
    {
      title: t('claims.gtin'),
      dataIndex: 'gtin',
      width: '7vw',
      key: 'gtin',
      className: 'truncated-desc-td',
      sorter: (a, b) => Compare(a.gtin, b.gtin),
      render: text => (
        <div title={text} className="truncated-desc-td Width6">
          {text}
        </div>
      )
    },
    {
      title: t('claims.uom'),
      dataIndex: 'uom',
      width: '5vw',
      key: 'uom',
      sorter: (a, b) => Compare(a.uom, b.uom),
      render: text => (
        <div title={text} className="truncated-desc-td width4">
          {text}
        </div>
      )
    },

    {
      title: t('claims.qty'),
      dataIndex: 'qty',
      width: '5vw',
      key: 'qty',
      sorter: (a, b) => Compare(a.qty, b.qty)
    },
    {
      title: t('claims.kg'),
      dataIndex: 'kg',
      width: '5vw',
      key: 'kg',
      sorter: (a, b) => Compare(a.kg, b.kg)
    }
  ];
  return colums;
}

export default getClaimDetailsColums;
