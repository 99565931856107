import { combineReducers } from 'redux';
import initialState from 'store/initialState';
import { USER, CLAIMTYPE, CLAIMDASH, PODETAIL, CLAIMDETAILS, CREATECLAIM } from 'actions/actionTypes';
import { createReducer } from 'reduxHelpers';

const user = createReducer(USER, initialState.user);
const claimType = createReducer(CLAIMTYPE, initialState.claimType);
const claimDashboard = createReducer(CLAIMDASH, initialState.claimDashboard);
const poDetail = createReducer(PODETAIL, initialState.poDetail);
const claimDetails = createReducer(CLAIMDETAILS, initialState.claimDetails);
const claimIdList = createReducer(CREATECLAIM, initialState.claimIdList);

// const test = (state = initialState.test, action) => {
//   switch (action.type) {
//     case CLEAR_PODETAIL:
//       console.log('actionactionaction', action.type , {
//         ...state, data: action.payload
//       });
//       return {
//         ...state, data: [1,2]
//       };
//     default:
//       return state;
//   }
// };

export default combineReducers({
  user,
  claimType,
  claimDashboard,
  poDetail,
  claimDetails,
  claimIdList,
  // test
});
