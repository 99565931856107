import React from 'react';
import { withTranslation } from 'react-i18next';
// import moment from 'moment';

export function NotePop({ comments }) {
  return (
    <div className="notes-wrapper">
      <div className="notes">
        {/* {comments.map(comment => {
          return (
            <div className="note" key={comment}>
              <div className="type">{comment}</div>
              <div className="note-content">{comment}</div>
            </div>
          );
        })} */}
        <div className="note" >{comments}</div>
      </div>
    </div>
  );
}

export default withTranslation()(NotePop);
