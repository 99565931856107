import React from 'react';
import Compare from 'utils/compare';
import { Link } from 'react-router-dom';
import ClaimsStateTag from 'components/ClaimsStateTag';
import GridHoverAction from 'components/GridHoverActions';

function getClaimColums(t, vendorId) {
  const columns = [
    {
      title: t('claims.id'),
      dataIndex: 'id',
      width: 70,
      key: 'claimsid',
      sorter: (a, b) => Compare(a.id, b.id),
      render: (claimsid, record) => (
        <Link
          to={{
            pathname: `/suite/claims/claim/${claimsid}`,
            state: {
              vendorId: vendorId,
              record: record
            }
          }}
        >
          {claimsid === 0 ? '' : claimsid}
        </Link>
      )
    },
    {
      title: t('claims.po'),
      dataIndex: 'po',
      width: 100,
      key: 'po',
      sorter: (a, b) => Compare(a.po, b.po),
      multiple: 6
    },
    {
      title: t('claims.vendor#'),
      dataIndex: 'vendorNo',
      width: 100,
      key: 'vendorNo'
    },
    {
      title: t('claims.vendorName'),
      dataIndex: 'vendorName',
      width: 100,
      key: 'vendorName'
    },
    {
      title: t('claims.operatingCompany'),
      dataIndex: 'opcoId',
      width: 100,
      key: 'opcoId',
      sorter: (a, b) => Compare(a.opcoId, b.opcoId)
    },
    {
      title: t('claims.type'),
      dataIndex: 'claimType',
      width: 120,
      key: 'claimType',
      sorter: (a, b) => Compare(a.claimType, b.claimType),
      render: text => (
        <div title={text} className="truncated-desc-td">
          {text}
        </div>
      )
    },
    {
      title: t('claims.claimDate'),
      dataIndex: 'claimDate',
      width: 100,
      key: 'claimDate',
      sorter: (a, b) => Compare(a.claimDate, b.claimDate)
    },
    {
      title: t('claims.payTo'),
      dataIndex: 'payTo',
      width: 80,
      key: 'payTo',
      sorter: (a, b) => Compare(a.payTo, b.payTo)
    },
    {
      title: t('claims.cbsmspo'),
      dataIndex: 'cbSMSPO',
      width: 120,
      key: 'cbSMSPO',
      sorter: (a, b) => Compare(a.cbSMSPO, b.cbSMSPO)
    },

    {
      title: t('claims.status'),
      dataIndex: 'status',
      key: 'status',
      width: 120,
      sorter: (a, b) => Compare(a.status, b.status),
      render: text => <ClaimsStateTag status={text} />
    },
    {
      title: t('claims.submitBy'),
      dataIndex: 'submittedBy',
      width: 100,
      key: 'submittedBy'
    },
    {
      title: t('claims.action'),
      dataIndex: 'action',
      width: 100,
      className: 'hover-actions',
      key: 'avtion',
      render: (text, record) => <GridHoverAction claimsid={record.id} vendorId={vendorId} record={record} />
    },
    {
      title: t('claims.approvedDate'),
      dataIndex: 'approvedDate',
      width: 100,
      key: 'approvedDate'
    }
  ];
  return columns;
}

export default getClaimColums;
