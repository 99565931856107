const initialState = {
  // test:{
  //   data:[]
  // },
  poDetail: {
    fetching: false,
    data: []
  },
  user: {
    fetching: true,
    data: {
      email: '',
      language: '',
      name: '',
      timeZone: '',
      userId: '',
      userRole: '',
      vendorList: [
        {
          opcos: ['', ''],
          vendorId: '',
          vendorName: '',
          vendorType: ''
        }
      ]
    },
    error: null
  },
  claimType: {
    fetching: false,
    data: {
      claimTypes: []
    },
    error: null
  },
  claimDashboard: {
    fetching: false,
    data: {
      claimsDashboard: []
    }
  },
  claimDetails: {
    fetching: false,
    data: {
      claimDate: '',
      claimStatus: '',
      claimType: '',
      id: 0,
      itemList: [],
      opcoId: '',
      po: 0,
      submittedBy: 'string',
      vendorNo: 'string'
    }
  },
  claimIdList:{
    fetching: false,
    data:{
      claimsId:[]
    }
  }
};

export default initialState;
