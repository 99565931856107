import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Claims from 'pages/Claims';
import ClaimDetails from 'pages/ClaimDetails';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import CreateClaimsView from 'pages/CreateClaimsView';
import * as batchesActions from 'actions';
import AppLogo from './styles/images/app-logos/claims-logo.svg';
import styles from './styles/ClaimsApp.scss';
import AppLoader from 'components/AppLoader';

const setAppLogo = () => {
  if (document.getElementById('app-logo')) {
    document.getElementById('app-logo').src = AppLogo;
  }
};

const setAppHeaderToAppMode = () => {
  if (document.getElementById('app-bar')) {
    document.getElementById('app-bar').className = 'app-bar app-mode';
  }
};

function MainApp({ actions, vendorId, userId, userFetching }) {
  setAppHeaderToAppMode();
  setAppLogo();

  useEffect(() => {
    styles.use();
    return () => {
      styles.unuse();
    };
  }, []);

  useEffect(() => {
    actions.loadUserDetails(userId);
  }, [actions, userId]);

  const fetching = !userFetching;
  return fetching ? (
    <div className="wrapper claims-wrapper">
      <Router>
        <Route exact path="/suite/claims" render={() => <Claims vendorId={vendorId} />} />
        <Route path="/suite/claims/createClaim" component={CreateClaimsView} />
        <Route path="/suite/claims/claim/:id" component={ClaimDetails} />
      </Router>
    </div>
  ) : (
    <AppLoader />
  );
}

function mapStateToProps(state) {
  return {
    user: state.user.data,
    userFetching: state.user.fetching,
  };
}
function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(batchesActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainApp);
