import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
// import { UserRole, BatchStatus } from 'enums';
import { Link } from 'react-router-dom';
// import SubmitModal from 'containers/SubmitModalContainer';
// import RejectModal from 'containers/RejectModalContainer';
// import DeleteModal from 'containers/DeleteModalContainer';
// import CancelModal from 'containers/CancelModalContainer';
// import ApproveModal from 'containers/ApproveModalContainer';
// import useModalToggle from 'hooks/useModalToggle';

export default function GridHoverAction({
  // userRole, 
  // status, 
  vendorId,
  record,
  claimsid
}) {
  const { t } = useTranslation();
  // const isVendorOrAdmin = userRole === UserRole.VENDOR || userRole === UserRole.ADMIN;
  // const isNotVendor = userRole !== UserRole.VENDOR;
  // const isCorpMerchOrAdmin = userRole === UserRole.CORP_MERCH || userRole === UserRole.ADMIN;
  // const isSubmittable = status === BatchStatus.OPEN;
  // const isRejectable = status === BatchStatus.REVIEWED;
  // const isCancelable = status === BatchStatus.PENDING;

  // const [isApproveBatchModalVisible, onToggleApproveBatchModalVisible] = useModalToggle(false);
  // const [isRejectBatchModalVisible, onToggleRejectBatchModalVisible] = useModalToggle(false);
  // const [isDeleteBatchModalVisible, onToggleDeleteBatchModalVisible] = useModalToggle(false);
  // const [isCancelBatchModalVisible, onToggleCancelBatchModalVisible] = useModalToggle(false);
  // const [isSubmitBatchModalVisible, onToggleSubmitBatchModalVisible] = useModalToggle(false);

  return (
    <div className="grid-hover-panel">
      <Link to={{
        pathname: `/suite/claims/claim/${claimsid}`,
        state: {
          vendorId: vendorId,
          record: record
        }
      }}
        style={{ textDecoration: 'none' }}>
        <Button className="btn unrounded white-action-btn">
          <i className="fi flaticon-folder"></i>
          {t('claim.view').toUpperCase()}
        </Button>
      </Link>
      {/* {isVendorOrAdmin && isSubmittable && (
        <React.Fragment>
          <Button className="btn unrounded green-action-btn invert" onClick={onToggleSubmitBatchModalVisible}>
            <i className="fi flaticon-tick-1"></i>
            {t('costing.navigationOption.submit').toUpperCase()}
          </Button>
          <SubmitModal
            visible={isSubmitBatchModalVisible}
            onToggle={onToggleSubmitBatchModalVisible}
            batchIds={[batchId]}
          />
        </React.Fragment>
      )}
      {isNotVendor && isRejectable && (
        <React.Fragment>
          <Button className="btn unrounded green-action-btn invert" onClick={onToggleApproveBatchModalVisible}>
            <i className="fi flaticon-tick-1"></i>
            {t('costing.batch.merchandiser.approve').toUpperCase()}
          </Button>
          <ApproveModal
            visible={isApproveBatchModalVisible}
            batchIds={[batchId]}
            onToggle={onToggleApproveBatchModalVisible}
          />
        </React.Fragment>
      )}
      {isCorpMerchOrAdmin && isRejectable && (
        <React.Fragment>
          <Button className="btn unrounded red-action-btn invert" onClick={onToggleRejectBatchModalVisible}>
            <i className="fi flaticon-round-delete-button"></i>
            {t('costing.batch.merchandiser.reject').toUpperCase()}
          </Button>
          <RejectModal
            visible={isRejectBatchModalVisible}
            onToggle={onToggleRejectBatchModalVisible}
            batchIds={[batchId]}
          />
        </React.Fragment>
      )} */}
      {/* <Button type="primary" className="btn unrounded green-action-btn invert">
        <i className="fi flaticon-download"></i>
        {t('costing.batch.download').toUpperCase()}
      </Button> */}

      {/* {isVendorOrAdmin && isSubmittable && (
        <React.Fragment>
          <Button
            type="primary"
            className="btn delete-btn red-action-btn invert icon-only unrounded"
            onClick={onToggleDeleteBatchModalVisible}
          >
            <i className="fi flaticon-delete"></i>
          </Button>
          <DeleteModal
            visible={isDeleteBatchModalVisible}
            batchId={batchId}
            onToggle={onToggleDeleteBatchModalVisible}
          />
        </React.Fragment>
      )}
      {isVendorOrAdmin && isCancelable && (
        <React.Fragment>
          <Button className="btn unrounded red-action-btn invert" onClick={onToggleCancelBatchModalVisible}>
            <i className="fi flaticon-round-delete-button"></i>
            {t('costing.createBatch.cancel').toUpperCase()}
          </Button>
          <CancelModal
            visible={isCancelBatchModalVisible}
            onToggle={onToggleCancelBatchModalVisible}
            batchIds={[batchId]}
          />
        </React.Fragment>
      )} */}
    </div>
  );
}
