export const NullOrEmpty = data => {
  if (data === null || data === '') {
    return true;
  } else {
    return false;
  }
};

export const NotnullOrEmptyOrUndefined = data => {
  if (data !== undefined && data !== null && data !== "") {
    return true;
  } else {
    return false;
  }
};

