import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
// import { claimStatus, UserRole, claimType } from 'enums';
// import { useTranslation } from 'react-i18next';
// import ClaimsStateTag from 'components/ClaimsStateTag';

export function CancelAndExitAndCreateModal({
  isClaimPage,
  vendorId,
  history,
  userRole,
  handleSaveClaim,
  actions
}) {

  const { t } = useTranslation();

  const createNew = () => {
    actions.emptyPoDetail({ poDetails: [] });
    history.push({
      pathname: `/suite/claims/createClaim`,
      state: { vendorId: vendorId }
    });
  };

  const createNewButton =()=>{
    return userRole === "vendor" ?  ( 
    <React.Fragment>
      <Button id="create-new-btn" type="primary" className="btn create-new-btn" onClick={createNew}>
        <i className="fi flaticon-add-button-inside-black-circle"></i>
      {t('claims.createNewClaims')}
    </Button>
    </React.Fragment>
  ) : null
}

  const  handleSave = item =>{
    handleSaveClaim(item);
  }

  const concelAndExit = () =>{
    history.push('/suite/claims')
  }

  return (
    <React.Fragment>
      {isClaimPage ? createNewButton() :
        (<React.Fragment>
          <Button id="claim-submitForApproval" type="primary" 
          onClick={e=>handleSave("submit")}
          className="btn green-action-btn">
            <i className="fi flaticon-tick-1"></i>
        {t('claims.submitForApproval')}
      </Button>
          <div className="dotted-connector"></div>
          <Button id="claim-save" type="primary" 
           onClick={e=>handleSave("save")}
          className="btn">
            <i className="fi flaticon-save"></i>
       {t('claims.save')}
      </Button>
          <Button id="claim-saveAndExit" type="primary" 
          onClick={e=>handleSave("saveAndExit")}
          className="btn">
            <i className="fi flaticon-save"></i>
       {t('claims.saveandExit')}
      </Button>
      <Button id="claim-cancelAndExit" 
      type="default" 
      className="btn cancel-btn"
      onClick={concelAndExit}
      >
       {t("claims.cancelandExit")}
      </Button>
        </React.Fragment>)}
    </React.Fragment>
  );
}

export default React.memo(CancelAndExitAndCreateModal);
