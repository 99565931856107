const transformState = date => {
  let status;
  switch (date) {
    case 'O':
      status = 'Open';
      break;
    case 'S':
      status = 'Submitted';
      break;
    case 'A':
      status = 'Approved';
      break;
    case 'OA':
      status = 'Opco Approved';
      break;
    case 'OR':
      status = 'Opco Reject';
      break;
    case 'I':
      status = 'In Progress';
      break;
    case 'R':
      status = 'Rejected';
      break;
    default:
      status = date;
      break;
  }
  return status;
};

export default transformState;
