import React, { useState } from 'react';
import { Modal, Button, Icon, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

export default function ApproveModal({
  visible,
  onToggle,
  actions,
  claimDetails: { id, po, opcoId },
  history,
  userRole,
  isReSubmit
}) {
  const { t } = useTranslation();

  const [commentValue, setCommentValue] = useState('');

  const onCancel = () => {
    onToggle(false);
  };

  const onOk = () => {
    onToggle(false);
    const approveStatus = userRole === 'corp' ? 'corp approve' : 'approve';
    console.log('userRole', userRole);
    console.log('approveStatus', approveStatus);
    const data = {
      reason: commentValue,
      opcoId: opcoId,
      po: po,
      id: id,
      history: history,
      status: approveStatus
    };
    console.log('approve test', actions, data);
    actions.updateClaimStatus(data);
  };

  const onCommentChange = e => {
    setCommentValue(e.target.value);
  };

  return (
    <Modal
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <div className="modal-btn-row" key="row-1">
          <Button id="approve-modal-no-btn" key="No" onClick={onCancel} className="btn">
            {t('claims.modal.no')}
          </Button>
          <Button
            id="approve-modal-yes-btn"
            key="Yes"
            onClick={onOk}
            disabled={!(2 <= commentValue.length && commentValue.length <= 1000)}
            className="btn green-action-btn ant-btn-primary"
          >
            {t('claims.modal.yes')}
          </Button>
        </div>
      ]}
    >
      <div className="modal-confirm-label">
        <Icon className="icon" type="question-circle" theme="filled" />
        <div className="sub">{isReSubmit === true ? t('claims.resubmitClaim') : t('claims.aysaClaim')}</div>
      </div>
      <Form.Item>
        <TextArea rows={4} onChange={onCommentChange} value={commentValue} />
      </Form.Item>
    </Modal>
  );
}
