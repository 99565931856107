import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import { withTranslation } from 'react-i18next';
// import StateDetails from 'constants/StateDetails';
import transformState from 'utils/transform';

export function ClaimsStateTag({ status }) {
  const state = transformState(status);
  return (
    // <Tag className='{StateDetails[status].className}' title={StateDetails[status].tooltip}>
    <Tag className={`state ${status}`} title={state}>
      {/* {StateDetails[status].description} */}
      {state}
    </Tag>
  );
}

export default withTranslation()(ClaimsStateTag);

ClaimsStateTag.propTypes = {
  statu: PropTypes.string,
  t: PropTypes.func
};
