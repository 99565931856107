import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
// import { withTranslation } from 'react-i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { Popover, Button, Badge, InputNumber, Upload } from 'antd';
import { withRouter } from 'react-router-dom';
import NotesPop from 'components/NotesPop';
import AppLoader from 'components/AppLoader';
import GetClaimDetailsColums from 'utils/getClaimDetailsColums';
import ReactResizeDetector from 'react-resize-detector';
import ClaimsStateTag from 'components/ClaimsStateTag';
import CancelAndExitAndCreateModal from 'components/CancelAndExitAndCreateModal';
import { NotnullOrEmptyOrUndefined } from 'utils/nullOrEmpty';
import ErrorMessageModal from 'components/ErrorMessageModal';
import DangerModal from 'components/DangerModal';
import ApproveModal from 'components/ApproveModal';
import OptionSelection from 'components/OptionSelection';
import NoteModal from 'components/NoteModal';

const baseURL = process.env.REACT_APP_BFF;

export function ClaimDetailsGeneral({
  t,
  actions,
  claimDetails,
  history,
  claimDetailsFetcing,
  userRole,
  claimTypes,
  userId,
  vendorId,
  email
}) {
  const {
    id,
    po,
    opcoId,
    opcoName,
    claimStatus,
    itemList,
    submittedBy,
    claimDate,
    claimType,
    opcoComments,
    corpComments,
    vendorNo
  } = claimDetails;

  const [gridScrollHeight, setgridScrollHeight] = useState('70vh');
  const [tableData, setTableData] = useState([]);
  const [rejectModle, setRejectModle] = useState(false);
  const [approveModle, setApproveModle] = useState(false);
  const [isBlur, setisBlur] = useState(true);

  useEffect(() => {
    setTableData(itemList);
  }, [itemList]);

  const onResize = (width, height) => {
    setgridScrollHeight(height - 300);
  };

  const onExit = () => {
    history.push(`/suite/claims`);
  };

  //save Claim details
  const saveClaim = item => {
    let data = {};
    let typeError = 0;
    // Verify Same supc should have deffrient Type
    tableData.forEach((value, index) => {
      let tableCopy = cloneDeep(tableData);
      tableCopy.splice(index, 1);
      tableCopy.forEach(value2 => {
        if (value.supc === value2.supc && value.claimType === value2.claimType) {
          typeError++;
        }
      });
    });
    if (typeError !== 0) {
      ErrorMessageModal({ errorTitle: t('claims.SaveTypeErrorTitle'), errorContent: t('claims.SaveTypeErrorContent') });
    } else {
      // filter empty ClaimType
      let saveData = [];
      let fullList = 0;
      tableData.forEach(data => {
        const { claimType, claimedPrice, claimedQty, extendedPrice } = data;
        if (item === 'submit' && NotnullOrEmptyOrUndefined(claimType)) {
          if (
            !NotnullOrEmptyOrUndefined(claimedPrice) ||
            !NotnullOrEmptyOrUndefined(claimedQty) ||
            !NotnullOrEmptyOrUndefined(extendedPrice)
          ) {
            fullList++;
          } else {
            saveData.push(data);
          }
        } else if (item !== 'submit') {
          saveData.push({ ...data, extendedPrice: data.extendedPrice });
        }
      });
      if (fullList !== 0) {
        ErrorMessageModal({ errorTitle: t('claims.typeFullDataTitle'), errorContent: t('claims.typeFullDataContent') });
      } else {
        if (saveData.length > 0) {
          data.claimDetails = saveData;
          data.opcoId = opcoId;
          data.po = po;
          data.submittedBy = userId;
          data.vendorNo = vendorId;
          data.vendorEmail = email;
          switch (item) {
            case 'submit':
              actions.createClaim(data, history);
              break;
            case 'save':
              actions.saveClaim(data);
              break;
            case 'saveAndExit':
              actions.saveClaim(data, history);
              break;
            default:
              break;
          }
        }
      }
    }
  };

  const rejectClaim = visible => {
    setRejectModle(visible);
  };

  const approveClaim = visible => {
    setApproveModle(visible);
  };

  const download = (value, record) => {
    console.log('download record', record);
    let uniqueIds = record.documentIdList;
    uniqueIds.forEach(uniqueId => {
      const url = `${baseURL}/download?uniqueId=${uniqueId}`;
      fetch(url)
        .then(res => {
          res.blob().then(blob => {
            let blobUrl = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = blobUrl;
            a.download = uniqueId;
            a.click();
            window.URL.revokeObjectURL(blobUrl);
          });
        })
        .catch(error => {
          console.log(error);
        });
    });

    // window.open(url);
  };

  const changeNote = (value, record, index, item) => {
    const list = cloneDeep(tableData);
    record[item] = value;
    if (item === 'notes') {
      record['visible'] = false;
    }
    list.splice(index, 1, record);
    setTableData(list);
  };

  const change = (value, record, index, item) => {
    const list = cloneDeep(tableData);
    record[item] = value;
    list.splice(index, 1, record);
    setTableData(list);
  };

  const changeClaimType = (value, record, index, item) => {
    change(value, record, index, item);
    change(null, record, index, 'claimedQty');
    change(null, record, index, 'claimedPrice');
    change(null, record, index, 'extendedPrice');
    if (value === 'PRICING') {
      changeQty(record.qty, record, index, 'claimedQty');
    } else if (record.claimType === 'QUANTITY(OS&D)') {
      changeClaimedPrice(record.vendorPOCost, record, index, 'claimedPrice');
    } else if (record.claimType === 'TAX') {
      changeQty(record.qty, record, index, 'claimedQty');
    }
  };

  const changeClaimedPrice = (value, record, index, item) => {
    change(value, record, index, item);
    let extendedPrice = 0;
    if (record.claimType === 'PRICING') {
      extendedPrice = (value - record.vendorPOCost) * record.qty;
      change(extendedPrice, record, index, 'extendedPrice');
    } else if (record.claimType === 'TAX') {
      extendedPrice = value * record.qty;
      change(extendedPrice, record, index, 'extendedPrice');
    } else if (record.claimType === 'ALLOWANCE' || record.claimType === 'OTHER') {
      extendedPrice = value * record.qty;
      change(extendedPrice, record, index, 'extendedPrice');
    }
  };

  // changeQty Modol will loss Blue ,need change to true
  const changeQty = (value, record, index, item) => {
    let claimedQtyError = 0;
    if (value > record.qty) {
      claimedQtyError = 1;
    }
    if (record.claimType === 'PRICING' && value > 7) {
      claimedQtyError = 2;
    }
    if (claimedQtyError === 1 && isBlur) {
      ErrorMessageModal({ errorTitle: t('claims.QtyDataErrorTitle'), errorContent: t('claims.QtyDataErrorContent') });
      change(0, record, index, item);
      setisBlur(false);
    } else if (claimedQtyError === 2 && isBlur) {
      ErrorMessageModal({ errorTitle: t('claims.QtyDataErrorTitle'), errorContent: t('claims.QtyShouldMaxUpTo7') });
      change(0, record, index, item);
      setisBlur(false);
    } else if (claimedQtyError === 0) {
      change(value, record, index, item);
      setisBlur(true);
    } else {
      setisBlur(true);
    }
    if (record.claimType === 'QUANTITY(OS&D)') {
      let extendedPrice = value * record.vendorPOCost;
      change(extendedPrice, record, index, 'extendedPrice');
    }
  };

  const colums1 = GetClaimDetailsColums(claimStatus, t);
  const colums = [
    ...colums1,
    {
      title: t('claims.claimType'),
      dataIndex: 'claimType',
      fixed: 'right',
      key: 'claimType',
      width: 100,
      render: (text, record, index) => (
        <OptionSelection
          id="typeFilter"
          value={text}
          className="table-select"
          placeholder="Please select"
          dropdownMatchSelectWidth={false}
          onChange={e => changeClaimType(e, record, index, 'claimType')}
          noAll={true}
          valueOptions={claimTypes.map(e => ({ id: e.name, name: e.name }))}
        />
      )
    },
    {
      title: t('claims.notes'),
      dataIndex: 'notes',
      key: 'notes',
      fixed: 'right',
      width: 70,
      render: (text, record, index) => {
        return claimStatus === 'O' ? (
          <React.Fragment>
            <Button
              className="btn icon-only notes-btn"
              onClick={() => {
                const list = cloneDeep(tableData);
                record['visible'] = true;
                list.splice(index, 1, record);
                setTableData(list);
              }}
            >
              <Badge dot>
                <i className="fi flaticon-comment"></i>
              </Badge>
            </Button>
            <NoteModal
              visible={record.visible}
              record={record}
              noteText={text}
              index={index}
              onChangeNote={changeNote}
              noteValue=""
            />
          </React.Fragment>
        ) : (
          <div className="notes">
            <Popover content={<NotesPop comments={text} />} trigger="click">
              <Button type="link" className="btn icon-only notes-btn ">
                <Badge dot>
                  <i className="fi flaticon-comment"></i>
                </Badge>
              </Button>
            </Popover>
          </div>
        );
      }
    },
    {
      title: t('claims.claimdQty'),
      dataIndex: 'claimedQty',
      width: 100,
      key: 'claimedQty',
      fixed: 'right',
      render: (text, record, index) => {
        return claimStatus === 'O' ? (
          <InputNumber
            value={text}
            className="table-input"
            onChange={value => changeQty(value, record, index, 'claimedQty')}
            onBlur={() => {
              setisBlur(true);
            }}
          />
        ) : (
          text
        );
      }
    },
    {
      title: t('claims.claimedPrice'),
      dataIndex: 'claimedPrice',
      width: 120,
      key: 'claimedPrice',
      fixed: 'right',
      render: (text, record, index) => {
        return claimStatus === 'O' ? (
          <InputNumber
            value={text}
            step={0.01}
            className="table-input"
            onChange={value => changeClaimedPrice(value, record, index, 'claimedPrice')}
          />
        ) : (
          text.toFixed(2)
        );
      }
    },
    {
      title: t('claims.extended'),
      dataIndex: 'extendedPrice',
      width: 80,
      key: 'extendedPrice',
      fixed: 'right',
      render: (text, record, index) => {
        return claimStatus === 'O' ? (
          <InputNumber
            value={text}
            step={0.01}
            className="table-input"
            onChange={value => change(value, record, index, 'extendedPrice')}
          />
        ) : (
          text.toFixed(2)
        );
      }
    },
    {
      title: '   ',
      dataIndex: 'update',
      key: 'update',
      width: 70,
      fixed: 'right',
      className: 'truncated-desc-td width4',
      render: (text, record, index) => (
        <Upload
          name="file"
          showUploadList={false}
          action={`${baseURL}/upload`}
          data={() => {
            const data = {};
            data.opcoId = opcoId;
            data.po = po;
            data.vendorId = userRole === 'vendor' ? vendorId : vendorNo;
            data.claimType = tableData[index].claimType;
            data.itemNo = tableData[index].supc;
            console.log(data);
            return data;
          }}
          disabled={tableData[index] !== undefined && tableData[index].claimType !== undefined ? false : true}
        >
          <button type="primary" className="table-button">
            <i className="fi flaticon-upload"></i>
            {t('claims.upload')}
          </button>
        </Upload>
      )
    },
    {
      title: '   ',
      dataIndex: 'add',
      key: 'add',
      width: 60,
      fixed: 'right',
      className: 'truncated-desc-td width4',
      render: (text, record, index) => {
        return (
          <button
            type="primary"
            className="table-button greenButton"
            onClick={() => {
              const list = cloneDeep(tableData);
              record.claimType = '';
              record.claimedQty = '';
              record.claimedPrice = '';
              record.extendedPrice = '';
              list.splice(index + 1, 0, record);
              setTableData(list);
            }}
          >
            <i className="fi flaticon-add-button-inside-black-circle"></i>
            {t('claims.add')}
          </button>
        );
      }
    },
    {
      title: '',
      dataIndex: 'viewDocs',
      width: 100,
      key: 'viewDocs',
      fixed: 'right',
      render: (text, record) => (
        <Button type="primary" className="btn" onClick={value => download(value, record)}>
          <i className="fi flaticon-loading"></i>
          {t('claims.viewDocs')}
        </Button>
      )
    }
  ];
  let list = [];
  colums.forEach(column => {
    if (claimStatus === 'O' || (column.key !== 'claimType' && column.key !== 'update' && column.key !== 'add')) {
      list.push(column);
    } else if (claimStatus === 'R' && column.key === 'update' && userRole === 'opco') {
      list.push(column);
    }
  });
  const fetching = claimDetailsFetcing;
  return (
    <React.Fragment>
      {fetching && <AppLoader show />}
      <div className="grid-filter-panel">
        <div className="claimDetail-title">
          <div className="with-sublable-wrapper">
            <div className="sublabel">{t('claims.claimDate').toUpperCase()}</div>
            <div className="with-sublable-title-icon">{moment(claimDate).format('MMM Do YYYY')}</div>
          </div>
          <div className="title-text">
            <label>{t('claims.po')}:</label>
            <span>{po}</span>
          </div>
        </div>
        <div className="claimDetail-filter">
          <div className="textInfo">
            <label htmlFor="opcoFilter">{t('claims.submittedBy')}:</label>
            <span>{submittedBy}</span>
          </div>
          <div className="textInfo">
            <label htmlFor="statusFilter">{t('claims.status')}:</label>
            <ClaimsStateTag status={claimStatus} />
          </div>

          <div className="filter">
            <label htmlFor="statusFilter">{t('claims.type')}:</label>
            <span>{claimStatus === 'O' ? null : claimType}</span>
          </div>
        </div>
        <div className="claimDetail-info">
          <div className="claim-date-label">
            <span className="id-lable">{t('claims.opco')}:</span>
            <div className="id-lable">{opcoId}</div>
          </div>
          <div className="spacer"></div>
          <div className="claim-date-label">
            <span className="id-lable">{t('claims.name')}:</span>
            <div className="id-lable">{opcoName}</div>
          </div>
        </div>
        {claimStatus === 'O' ? (
          <CancelAndExitAndCreateModal isClaimPage={false} handleSaveClaim={saveClaim} history={history} />
        ) : (
          <React.Fragment>
            {userRole === 'vendor' ||
            claimStatus === 'R' ||
            claimStatus === 'A' ||
            (userRole === 'corp' && (claimStatus === 'OR' || claimStatus === 'S')) ||
            (userRole === 'opco' && (claimStatus === 'OR' || claimStatus === 'OA')) ? null : (
              <React.Fragment>
                <Button className="btn" type="primary" onClick={e => approveClaim(true)}>
                  {t('claims.merchandiser.approve')}
                </Button>

                <ApproveModal
                  visible={approveModle}
                  onToggle={approveClaim}
                  actions={actions}
                  claimDetails={claimDetails}
                  history={history}
                  userRole={userRole}
                />

                <Button className="btn red-action-btn" type="primary" onClick={e => rejectClaim(true)}>
                  {t('claims.merchandiser.reject')}
                </Button>

                <DangerModal
                  visible={rejectModle}
                  onToggle={rejectClaim}
                  userRole={userRole}
                  actions={actions}
                  claimDetails={claimDetails}
                  history={history}
                />
              </React.Fragment>
            )}
            {userRole === 'opco' && claimStatus === 'R' ? (
              <React.Fragment>
                <Button className="btn" type="primary" onClick={e => approveClaim(true)}>
                  {t('claims.reSubmitForApproval')}
                </Button>

                <ApproveModal
                  visible={approveModle}
                  onToggle={approveClaim}
                  actions={actions}
                  claimDetails={claimDetails}
                  history={history}
                  userRole={userRole}
                  isReSubmit={true}
                />
              </React.Fragment>
            ) : null}
            <Button id="batch-backToBatchList" type="link" className="btn back-btn" onClick={onExit}>
              <i className="fi flaticon-arrow"></i>
              {t('claims.backToList')}
            </Button>
          </React.Fragment>
        )}
        <div className="claimDetail-info">
          <div className="claim-date-label">
            <span className="id-lable">{t('claims.claimID')}:</span>
            <div className="id-lable">{id}</div>
          </div>
        </div>
        <div className="claimDetail-info">
          <div className="claim-date-label">
            <label>{t('claims.opcoComments')}</label>
            <div className="notes">
              <Popover content={<NotesPop comments={opcoComments} />} trigger="click">
                <Button type="link" className="btn icon-only notes-btn ">
                  <Badge dot>
                    <i className="fi flaticon-comment"></i>
                  </Badge>
                </Button>
              </Popover>
            </div>
          </div>
          <div className="claim-date-label">
            <label>{t('claims.corpComments')}</label>
            <div className="notes">
              <Popover content={<NotesPop comments={corpComments} />} trigger="click">
                <Button type="link" className="btn icon-only notes-btn ">
                  <Badge dot>
                    <i className="fi flaticon-comment"></i>
                  </Badge>
                </Button>
              </Popover>
            </div>
          </div>
        </div>
      </div>

      <ReactResizeDetector handleHeight onResize={onResize} targetDomEl={this}>
        <div className="grid-panel">
          <Table
            pagination={{ pageSize: 50, hideOnSinglePage: true }}
            columns={list}
            dataSource={tableData}
            scroll={{ x: 1600, y: gridScrollHeight }}
            rowKey={(record, index) => index}
          />
        </div>
      </ReactResizeDetector>
    </React.Fragment>
  );
}

export default withRouter(ClaimDetailsGeneral);

ClaimDetailsGeneral.propTypes = {
  actions: PropTypes.any,
  claimDetails: PropTypes.object,
  claimDetailsFetcing: PropTypes.bool,
  location: PropTypes.object
};
