import React, { useEffect, useState } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import ClaimsStateTag from 'components/ClaimsStateTag';
import CancelAndExitAndCreateModal from 'components/CancelAndExitAndCreateModal';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { Button, Badge, Table, InputNumber, Upload } from 'antd';
import AppLoader from 'components/AppLoader';
import NoteModal from 'components/NoteModal';
import getNewClaimColums from 'utils/getNewClaimColums';
import OptionSelection from 'components/OptionSelection';
import moment from 'moment';
import { NotnullOrEmptyOrUndefined } from 'utils/nullOrEmpty';
import { useTranslation } from 'react-i18next';
import ErrorMessageModal from 'components/ErrorMessageModal';

const baseURL = process.env.REACT_APP_BFF;

export default function NewClaimGeneral({
  actions,
  userId,
  email,
  claimType,
  poDetail,
  vendorId,
  vendorList,
  history,
  claimTypefetching,
  poDetailfetching,
  vendorListFetching
}) {
  const [gridScrollHeight, setGridScrollHeight] = useState('70vh');
  const [tableData, setTableData] = useState([]);
  const [poIsrequest, setpoIsrequest] = useState(false);
  const [opcoIsrequest, setopcoIsrequest] = useState(false);
  const [useforSave, setuseforSave] = useState({
    opcoId: '',
    po: ''
  });
  //Must use Modol then can useful
  const [isBlur, setisBlur] = useState(true);
  const [opcos, setOpcos] = useState([]);
  const [searchData, setSearchData] = useState({
    vendorId: vendorId
  });

  const { t } = useTranslation();

  useEffect(() => {
    setTableData(() => {
      let list = [];
      poDetail.forEach(po => {
        po.visible = false;
        list.push(po);
      });
      return list;
    });
  }, [poDetail]);

  useEffect(() => {
    vendorList.forEach(vedor => {
      if (vedor.vendorId === vendorId) {
        setOpcos(vedor.opcos);
      }
    });
  }, [vendorList, opcos, vendorId]);

  const onResize = (width, height) => {
    setGridScrollHeight(height - 200);
  };

  //save Claim details
  const saveClaim = item => {
    let data = {};
    let typeError = 0;
    // Verify Same supc should have deffrient Type
    tableData.forEach((value, index) => {
      let tableCopy = cloneDeep(tableData);
      tableCopy.splice(index, 1);
      tableCopy.forEach(value2 => {
        if (value.supc === value2.supc && value.claimType === value2.claimType) {
          typeError++;
        }
      });
    });
    if (typeError !== 0) {
      ErrorMessageModal({ errorTitle: t('claims.SaveTypeErrorTitle'), errorContent: t('claims.SaveTypeErrorContent') });
    } else {
      // filter empty ClaimType
      let saveData = [];
      let fullList = 0;
      // not Full data can not save
      tableData.forEach(data => {
        const { claimType, claimedPrice, claimedQty, extendedPrice } = data;
        if (item === 'submit' && NotnullOrEmptyOrUndefined(claimType)) {
          if (
            !NotnullOrEmptyOrUndefined(claimedPrice) ||
            !NotnullOrEmptyOrUndefined(claimedQty) ||
            !NotnullOrEmptyOrUndefined(extendedPrice)
          ) {
            fullList++;
          } else {
            saveData.push(data);
          }
        } else if (item !== 'submit') {
          saveData.push({ ...data, extendedPrice: data.extendedPrice });
        }
      });
      if (fullList !== 0) {
        ErrorMessageModal({ errorTitle: t('claims.typeFullDataTitle'), errorContent: t('claims.typeFullDataContent') });
      } else {
        if (saveData.length > 0) {
          data.claimDetails = saveData;
          data.opcoId = useforSave.opcoId;
          data.po = useforSave.po;
          data.submittedBy = userId;
          data.vendorNo = vendorId;
          data.vendorEmail = email;
          switch (item) {
            case 'submit':
              actions.createClaim(data, history);
              break;
            case 'save':
              actions.saveClaim(data);
              break;
            case 'saveAndExit':
              actions.saveClaim(data, history);
              break;
            default:
              break;
          }
        }
      }
    }
  };

  const onChangePO = e => {
    e.persist();
    setpoIsrequest(false);
    setSearchData({ ...searchData, po: e.target.value });
  };

  const onChangeOpco = value => {
    let data = value === t('claims.all') ? { ...searchData, opcoId: '' } : { ...searchData, opcoId: value };
    setSearchData(data);
    setopcoIsrequest(false);
  };

  const change = (value, record, index, item) => {
    const list = cloneDeep(tableData);
    record[item] = value;
    if (item === 'notes') {
      record['visible'] = false;
    }
    list.splice(index, 1, record);
    setTableData(list);
  };

  const changeClaimType = (value, record, index, item) => {
    change(value, record, index, item);
    change(null, record, index, 'claimedQty');
    change(null, record, index, 'claimedPrice');
    change(null, record, index, 'extendedPrice');
    if (value === 'PRICING') {
      changeQty(record.qty, record, index, 'claimedQty');
    } else if (record.claimType === 'QUANTITY(OS&D)') {
      changeClaimedPrice(record.vendorPOCost, record, index, 'claimedPrice');
    } else if (record.claimType === 'TAX') {
      changeQty(record.qty, record, index, 'claimedQty');
    }
  };

  const changeClaimedPrice = (value, record, index, item) => {
    change(value, record, index, item);
    let extendedPrice = 0;
    if (record.claimType === 'PRICING') {
      extendedPrice = (value - record.vendorPOCost) * record.qty;
      change(extendedPrice, record, index, 'extendedPrice');
    } else if (record.claimType === 'TAX') {
      extendedPrice = value * record.qty;
      change(extendedPrice, record, index, 'extendedPrice');
    } else if (record.claimType === 'ALLOWANCE' || record.claimType === 'OTHER') {
      extendedPrice = value * record.qty;
      change(extendedPrice, record, index, 'extendedPrice');
    }
  };

  // changeQty Modol will loss Blue ,need change to true
  const changeQty = (value, record, index, item) => {
    let claimedQtyError = 0;
    if (value > record.qty) {
      claimedQtyError = 1;
    }
    if (record.claimType === 'PRICING' && value > 7) {
      claimedQtyError = 2;
    }
    if (claimedQtyError === 1 && isBlur) {
      ErrorMessageModal({ errorTitle: t('claims.QtyDataErrorTitle'), errorContent: t('claims.QtyDataErrorContent') });
      change(0, record, index, item);
      setisBlur(false);
    } else if (claimedQtyError === 2 && isBlur) {
      ErrorMessageModal({ errorTitle: t('claims.QtyDataErrorTitle'), errorContent: t('claims.QtyShouldMaxUpTo7') });
      change(0, record, index, item);
      setisBlur(false);
    } else if (claimedQtyError === 0) {
      change(value, record, index, item);
      setisBlur(true);
    } else {
      setisBlur(true);
    }
    if (record.claimType === 'QUANTITY(OS&D)') {
      let extendedPrice = value * record.vendorPOCost;
      change(extendedPrice, record, index, 'extendedPrice');
    }
  };

  const searchPo = () => {
    if (!NotnullOrEmptyOrUndefined(searchData.po)) setpoIsrequest(true);
    if (!NotnullOrEmptyOrUndefined(searchData.opcoId)) setopcoIsrequest(true);
    if (NotnullOrEmptyOrUndefined(searchData.po) && NotnullOrEmptyOrUndefined(searchData.opcoId)) {
      let data = {};
      data.po = searchData.po;
      data.opcoId = searchData.opcoId;
      actions.loadpoDetails(searchData);
      setuseforSave(data);
    }
  };

  const columns1 = getNewClaimColums(t);
  const columns = [
    ...columns1,
    {
      title: t('claims.claimType'),
      dataIndex: 'claimType',
      fixed: 'right',
      key: 'claimType',
      width: 100,
      render: (text, record, index) => (
        <OptionSelection
          id="typeFilter"
          className="table-select"
          placeholder="Please select"
          dropdownMatchSelectWidth={false}
          onChange={e => changeClaimType(e, record, index, 'claimType')}
          defaultValue=""
          noAll={true}
          valueOptions={claimType.map(e => ({ id: e.name, name: e.name }))}
        />
      )
    },
    {
      title: t('claims.notes'),
      dataIndex: 'visible',
      key: 'visible',
      fixed: 'right',
      width: 70,
      render: (text, record, index) => {
        return (
          <React.Fragment>
            <Button
              className="btn icon-only notes-btn"
              onClick={() => {
                const list = cloneDeep(tableData);
                record['visible'] = !text;
                list.splice(index, 1, record);
                setTableData(list);
              }}
            >
              <Badge dot>
                <i className="fi flaticon-comment"></i>
              </Badge>
            </Button>
            <NoteModal
              visible={text}
              record={record}
              noteText={record.notes}
              index={index}
              onChangeNote={change}
              noteValue=""
            />
          </React.Fragment>
        );
      }
    },

    {
      title: t('claims.claimdQty'),
      dataIndex: 'claimedQty',
      key: 'claimedQty',
      className: 'truncated-desc-td',
      fixed: 'right',
      width: 90,
      render: (text, record, index) => (
        <InputNumber
          value={text}
          className="table-input"
          onChange={value => changeQty(value, record, index, 'claimedQty')}
          onBlur={() => {
            setisBlur(true);
          }}
        />
      )
    },
    {
      title: t('claims.claimedPrice'),
      dataIndex: 'claimedPrice',
      key: 'claimedPrice',
      className: 'truncated-desc-td',
      fixed: 'right',
      width: 95,
      render: (text, record, index) => (
        // <Form.Item>
        <InputNumber
          value={text}
          step={0.01}
          className="table-input"
          onChange={value => changeClaimedPrice(value, record, index, 'claimedPrice')}
        />
      )
      // </Form.Item>
    },
    {
      title: t('claims.extended'),
      dataIndex: 'extendedPrice',
      key: 'extendedPrice',
      className: 'truncated-desc-td',
      fixed: 'right',
      width: 80,
      render: (text, record, index) => (
        // <Form.Item>
        <InputNumber
          value={text}
          step={0.01}
          className="table-input"
          onChange={value => change(value, record, index, 'extendedPrice')}
        />
        // </Form.Item>
      )
    },
    {
      title: '   ',
      dataIndex: 'update',
      key: 'update',
      width: 70,
      fixed: 'right',
      className: 'truncated-desc-td width4',
      render: (text, record, index) => (
        <Upload
          name="file"
          showUploadList={false}
          action={`${baseURL}/upload`}
          data={() => {
            const data = {};
            data.opcoId = searchData.opcoId;
            data.po = searchData.po;
            data.vendorId = searchData.vendorId;
            data.claimType = tableData[index].claimType;
            data.itemNo = tableData[index].supc;
            console.log(data);
            return data;
          }}
          disabled={tableData[index] !== undefined && tableData[index].claimType !== undefined ? false : true}
        >
          <button type="primary" className="table-button">
            <i className="fi flaticon-upload"></i>
            {t('claims.upload')}
          </button>
        </Upload>
      )
    },
    {
      title: '   ',
      dataIndex: 'add',
      key: 'add',
      width: 60,
      fixed: 'right',
      className: 'truncated-desc-td width4',
      render: (text, record, index) => {
        return (
          <button
            type="primary"
            className="table-button greenButton"
            onClick={() => {
              const list = cloneDeep(tableData);
              record.claimType = '';
              record.claimedQty = '';
              record.claimedPrice = '';
              record.extendedPrice = '';
              list.splice(index + 1, 0, record);
              setTableData(list);
            }}
          >
            <i className="fi flaticon-add-button-inside-black-circle"></i>
            {t('claims.add')}
          </button>
        );
      }
    }
  ];
  const fetching = claimTypefetching || poDetailfetching || vendorListFetching;
  return (
    <React.Fragment>
      {fetching && <AppLoader show />}
      <div id="header-section">
        <div className="claims-view-header grid-filter-panel">
          <div className="title">
            <div className="with-sublable-wrapper">
              <div className="sublabel">
                <label>{t('claims.claimDate')}</label>
              </div>
              <div className="sublabel-bold">{moment(new Date()).format('MMM Do YYYY')}</div>
            </div>
          </div>

          <div className="claim-info">
            <div className="main-info">
              <div className="claim-date-label">
                <ClaimsStateTag status={'open'} />
              </div>
            </div>
          </div>

          <CancelAndExitAndCreateModal isClaimPage={false} handleSaveClaim={saveClaim} history={history} />
        </div>

        <div className="grid-filter-panel">
          <div className="claim-form">
            <div className="left">
              <div className="claim-field">
                <input type="search" className="search" placeholder="PO#" onChange={onChangePO} />
                <label className={poIsrequest ? 'lableShow' : 'lableHide'}>{t('claims.pleaseSelectOpco')}</label>
              </div>
              <div className="filter">
                <div className="filter-option">
                  <label htmlFor="opcoFilter">{t('claims.opco')}</label>
                  <OptionSelection
                    id="opcoFilter"
                    className="filter-select"
                    placeholder="Please select"
                    dropdownMatchSelectWidth={false}
                    onChange={onChangeOpco}
                    noAll={true}
                    defaultValue=""
                    // valueOptions={opcos.map(opco => ({ id: 1, name: 2 }))}
                    valueOptions={opcos.map(opco => ({ id: opco.opcoId, name: opco.opcoId + '-' + opco.opcoName }))}
                  />
                </div>
                <label className={opcoIsrequest ? 'lableShow' : 'lableHide'}>{t('claims.pleaseSelectOpco')}</label>
              </div>
              <Button type="primary" size="small" className="btn green-action-btn" onClick={searchPo}>
                {t('claims.search')}
              </Button>
            </div>
            {/* <div className="right">
              <Button type="link" className="btn newEmpty-btn  download-all-btn">
                <i className="fi flaticon-download"></i> Download All
              </Button>
            </div> */}
          </div>
        </div>
      </div>
      <ReactResizeDetector handleHeight onResize={onResize} targetDomEl={this}>
        <div className="grid-panel">
          <Table
            pagination={false}
            columns={columns}
            dataSource={tableData}
            //1770 'calc(1080px + 50%)'
            scroll={{ x: 1740, y: gridScrollHeight }}
            rowKey={(record, index) => index}
          />
        </div>
      </ReactResizeDetector>
    </React.Fragment>
  );
}

NewClaimGeneral.propTypes = {
  // userId: propTypes.string,
  // claimType: propTypes.array,
  // poDetail: propTypes.array,
  claimTypefetching: PropTypes.bool,
  poDetailfetching: PropTypes.bool
};
