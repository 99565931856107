const statusDropdown = [
  { id: 'O', name: 'Open' },
  { id: 'S', name: 'Submitted' },
  { id: 'A', name: 'Approved' },
  { id: 'OA', name: 'Opco Approved' },
  { id: 'OR', name: 'Opco Rejected' },
  { id: 'R', name: 'Rejected' }
];

export { statusDropdown };
