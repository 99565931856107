import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import * as claimsActions from 'actions';
import PropTypes from 'prop-types';
import ClaimGeneral from 'components/ClaimGeneral';

export function Claims({
  actions,
  t,
  claimTypeList,
  vendorList,
  userRole,
  userData,
  vendorId,
  claimDashboard,
  userFetching,
  vendorListFetching,
  claimTypeFetching,
  claimDashboardFetching
}) {
  useEffect(() => {
    if (claimTypeList.claimTypes.length === 0) {
      actions.loadClaimType();
    }
  }, [actions, claimTypeList]);

  return (
    <ClaimGeneral
      actions={actions}
      vendorId={vendorId}
      userRole={userRole}
      userData={userData}
      vendorList={vendorList}
      claimType={claimTypeList}
      claimDashboard={claimDashboard}
      vendorListFetching={vendorListFetching}
      claimTypeFetching={claimTypeFetching}
      userFetching={userFetching}
      claimDashboardFetching={claimDashboardFetching}
      t={t}
    />
  );
}

function mapStateToProps(state) {
  return {
    vendorList: state.user.data.vendorList,
    userRole: state.user.data.userRole,
    userData: state.user.data,
    vendorListFetching: state.user.fetching,
    userFetching: state.user.data.fetching,
    claimTypeList: state.claimType.data,
    claimTypeFetching: state.claimType.fetching,
    claimDashboard: state.claimDashboard.data.claimsDashboard,
    claimDashboardFetching: state.claimDashboard.fetching
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(claimsActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(Claims)));

Claims.propTypes = {
  vendorList: PropTypes.array,
  vendorId: PropTypes.string,
  userRole: PropTypes.string,
  userData: PropTypes.object,
  vendorListFetching: PropTypes.bool,
  userFetching: PropTypes.bool,
  claimDashboardFetching: PropTypes.bool,
  action: PropTypes.any,
  claimTypeList: PropTypes.object,
  claimDashboard: PropTypes.array
};
